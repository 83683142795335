import { render, staticRenderFns } from "./FreeTierCard.vue?vue&type=template&id=749ce3ce&scoped=true&"
import script from "./FreeTierCard.vue?vue&type=script&lang=js&"
export * from "./FreeTierCard.vue?vue&type=script&lang=js&"
import style0 from "./FreeTierCard.vue?vue&type=style&index=0&id=749ce3ce&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "749ce3ce",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src532116556/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('749ce3ce')) {
      api.createRecord('749ce3ce', component.options)
    } else {
      api.reload('749ce3ce', component.options)
    }
    module.hot.accept("./FreeTierCard.vue?vue&type=template&id=749ce3ce&scoped=true&", function () {
      api.rerender('749ce3ce', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/FreeTierCard.vue"
export default component.exports
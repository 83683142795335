import { render, staticRenderFns } from "./EmailUpdateVerification.vue?vue&type=template&id=5d704a26&scoped=true&"
import script from "./EmailUpdateVerification.vue?vue&type=script&lang=js&"
export * from "./EmailUpdateVerification.vue?vue&type=script&lang=js&"
import style0 from "./EmailUpdateVerification.vue?vue&type=style&index=0&id=5d704a26&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d704a26",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src532116556/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5d704a26')) {
      api.createRecord('5d704a26', component.options)
    } else {
      api.reload('5d704a26', component.options)
    }
    module.hot.accept("./EmailUpdateVerification.vue?vue&type=template&id=5d704a26&scoped=true&", function () {
      api.rerender('5d704a26', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/account/EmailUpdateVerification.vue"
export default component.exports
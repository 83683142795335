import { render, staticRenderFns } from "./ProjectSelector.vue?vue&type=template&id=1a6edc44&"
import script from "./ProjectSelector.vue?vue&type=script&lang=js&"
export * from "./ProjectSelector.vue?vue&type=script&lang=js&"
import style0 from "./ProjectSelector.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAutocomplete,VBtn,VDataTable,VForm,VIcon,VSelect,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src532116556/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1a6edc44')) {
      api.createRecord('1a6edc44', component.options)
    } else {
      api.reload('1a6edc44', component.options)
    }
    module.hot.accept("./ProjectSelector.vue?vue&type=template&id=1a6edc44&", function () {
      api.rerender('1a6edc44', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/projects/ProjectSelector.vue"
export default component.exports